@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

$photoSize: 116px;
$photoSizeMedium: 106px;
$photoSizeSmall: 95px;
$photoSizeXSmall: 85px;
$photoSizeMobile: 72px;

.employees {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax($photoSize, 1fr));
    gap: 5px;

    padding-bottom: 80px;
    margin-bottom: -80px;
    margin-top: 50px;
    scrollbar-color: #888 $color-background;
    scrollbar-width: thin;

    overflow-y: hidden;

    &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: $color-background;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 4px;
        border: 2px solid $color-background;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

.photo {
    width: $photoSize;
    height: $photoSize;
}

.filter {
    width: 100%;
    max-width: 1400px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    &__button {
        // TODO Update button component instead styling hacks
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        border-radius: 40px;
        gap: 10px;

        min-width: unset;

        
        & > span { 
            margin-left: 0 !important;
        }

        &_container {
            margin: 0;
            padding: 0;
            border: none;

            &:not(:last-child) {
                margin-right: 0;
            }

            @include media-mobile {
                width: 100%;
            }
        }
    }

    // @include media-mobile {
    //     display: none;
    // }
}


.dropdown-wrapper {
    display: none;
    height: 50px;
    // @include media-mobile {
    //     display: block;
    // }
}

.dropdown {
    background-color: #ffffff;
    border-radius: 76px;

    & .filter__button {
        border-radius: 76px;
    }

    & .filter__button_container {
        padding: 0;
        margin: 0;

        border-radius: 76px;
    }
}

@include medium-breakpoint {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax($photoSizeMedium, 1fr));
    }

    .photo {
        width: $photoSizeMedium;
        height: $photoSizeMedium;
    }
}

@include small-breakpoint {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax($photoSizeSmall, 1fr));
    }

    .photo {
        width: $photoSizeSmall;
        height: $photoSizeSmall;
    }

    .filter {
        display: none;
        &__button {
            &_container {
                width: 100%;
            }
        }
    }

    .dropdown-wrapper {
        display: block;
        width: 100%
    }
}

@include xsmall-breakpoint {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax($photoSizeXSmall, 1fr));
    }

    .photo {
        width: $photoSizeXSmall;
        height: $photoSizeXSmall;
    }
}

@include media-mobile {
    .employees-wrapper {
        display: block;
    }

    .employees {
        margin-top: 28px;
        grid-auto-flow: row;
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
        grid-template-rows: unset;
    }

    .photo {
        width: 120px;
        height: 120px;
    }

    .dropdown-wrapper {
        display: block;
        width: 100%;
    }
}

@media only screen and (max-width: 530px) {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }

    .photo {
        width: 100px;
        height: 100px;
    }
}

@media only screen and (max-width: 450px) {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
    }

    .photo {
        width: 85px;
        height: 85px;
    }
}

@media only screen and (max-width: 390px) {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax($photoSizeMobile, 1fr));
    }

    .photo {
        width: $photoSizeMobile;
        height: $photoSizeMobile;
    }
}

@media only screen and (max-width: 335px) {
    .employees {
        grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
    }

    .photo {
        width: 60px;
        height: 60px;
    }
}