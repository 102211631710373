@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

.dropdown {
    border-radius: 20px;
    height: auto;
    transition: height 2s ease-in-out;

    position: relative;
    height: 0;

    & .filter__button {
        border-radius: 10px;
    }

    & .filter__button_container {
        padding: 0;
        margin: 0;
    }

    &__button {
        display: flex;
        justify-content: space-between;
        background: transparent;
        color: $color-primary;
        padding: 15px 25px;
        outline: none;
        cursor: pointer;
        text-decoration: none;
        transition: all 0.185s ease-in-out;
    }

    &__arrow {
        width: 20px;
        height: 20px;
        transition: all 0.185s ease-in-out;
        user-select: none;
        pointer-events: none;

        margin-left: auto;

        &_active {
            transform: rotate(180deg);
        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 10px;
        padding: 10px;

    }

}

.dropdown-content-wrapper {
    position: absolute;
    bottom: 0;
    left: -2px;
    right: -2px;
    width: calc(100% + 4px);
    height: fit-content;

    z-index: 3;

    transform: translateY(100%);

    background-color: #ffffff;
    border-radius: 20px;
    border: 2px solid #20836D;
}

@include media-tablet-portrait {
    .dropdown {
        &__content {
            grid-template-columns: 1fr;
        }
    }
}