@mixin media-mobile-xs {
    @media screen and (max-width: 374px) {
        @content;
    }
}

@mixin media-mobile {
    @media screen and (max-width: 568px) {
        @content;
    }
}

@mixin media-mobile-up {
    @media screen and (min-width: 569px) {
        @content;
    }
}

@mixin media-tablet-only {
    @media screen and (min-width: 569px) and (max-width: 1024px) {
        @content;
    }
}

@mixin media-tablet-portrait {
    @media screen and (max-width: 768px) {
        @content;
    }
}

@mixin media-tablet-portrait-up {
    @media screen and (min-width: 769px) {
        @content;
    }
}

@mixin media-tablet {
    @media screen and (max-width: 1024px) {
        @content;
    }
}

@mixin media-table-large {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin media-laptop {
    @media screen and (max-width: 1366px) {
        @content;
    }
}

@mixin media-tablet-up {
    @media screen and (min-width: 1025px) {
        @content;
    }
}

@mixin media-desktop-md {
    @media screen and (max-width: 1440px) {
        @content;
    }
}

@mixin media-desktop-xm {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin media-desktop-large {
    @media screen and (min-width: 1920px) {
        @content;
    }
}