.r-mobile {
    display: none;
}

@include media-mobile {
    .r-mobile {
        display: inherit;
    }

    .r-not-mobile {
        display: none;
    }
}