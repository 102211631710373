$menu-height: 64px;
$content-margin: 76px;
$menu-height--fixed: 64px;
$menu-height--tablet: 64px;
$content-margin--tablet: 70px;
$menu-width: 280px;
$menu-width--mobile: 40px;
$menu-width--collapsed: 78px;
$footer-height: 120px;

$table-header-padding: 360px;

$font-size--xsmall: 12px;
$font-size--small: 14px;
$font-size--normal: 16px;
$font-size--large: 18px;
$font-size--xlarge: 20px;
$font-size--xxlarge: 22px;

$font-size-subtitle: 32px;
$font-size-value: 36px;
$font-size-title: 48px;

$border-radius: 12px;

$box-shadow: 0px 0px 16px rgba(191, 191, 191, 0.25);

$color-background: #F1F1F1;
$color-blue: #276EC3;
$color-green: #00AA85;
$color-green-opaque: rgba(0, 170, 133, 0.9);
$color-green-light: #97D8CA;
$color-greenish: #0BD194;
$color-red: #FFCFCF;
$color-red--text: #F23C3C;
$color-reddish: #EB5757;
$color-orange: #FFBB0B;
$color-grey: #C8C8C8;
$color-grey--dark: #A2A8AD;
$color-grey--light: #D5DADC;
$color-gallery: #ededed;
$color-black: #262726;
$color-ultra: #D24D0B;

$focus-outline-color: rgba(0, 150, 255, 0);
$focus-outline-distance: 4px;
$focus-outline-width: 0;

$color-title-active: #1E2021;
/** Body - Darken Gray */
$color-body: #64696C;
/** Grey Dark */
$color-grey-dark: #A2A8AD;
$color-grey: #C8CED2;
$color-grey-light: #D5DADC;
$color-background: #F3F4F5;
$color-input-background: #EBEEEF;
$color-off-white: #FFFFFF;

// Brand and style color palette

/** Dark Green */
$color-primary: #20836D;
$color-primary-opaque: #20836D26;
$color-primary-dark: #185F4F;
$color-primary-light: #2BBA9A;
/** Green */
$color-secondary: #2CAC8D;
$color-secondary-light: #6CDDC2;
/** Light green / Brand green */
$color-success: #00BA88;
$color-success-dark: #00966D;
/** Red */
$color-error: #EB0038;
$color-error-dark: #9C0025;
/** Pink */
$color-background-error: #ffdde5;
/** Orange */
$color-warning: #F2C94C;
/** Gold */
$color-warning2: #BF920D;
/** Violet */
$color-premium: #190038;
/** Soft Green */
$color-info: #8ACBB0;

/**Gradients */
$color-gradient-main: linear-gradient(115.37deg, #1F7A66 8.32%, #22997E 75.19%, #41BCA0 103.42%);
$color-gradient-secondary: linear-gradient(114.44deg, #4147D1 0%, #6CDDC2 100%);
$color-gradient-accent: linear-gradient(114.44deg, #EB0038 0%, #FFE289 100%);
$color-gradient-premium: linear-gradient(115.37deg, #3B186800 55.19%, #190038 103.42%);
$color-gradient-premium_revert: linear-gradient(115.37deg, #190038 55.19%, #3B186800 103.42%);
$color-gradient-standard: linear-gradient(115.37deg, #3B186800 55.19%, #20836D 103.42%);
$color-gradient-standard_revert: linear-gradient(115.37deg, #20836D 55.19%, #3B186800 103.42%);
$color-gradient-pin: linear-gradient(180deg, rgba(32, 131, 109, 0.31) 0.01%, #20836D 100%);
$color-gradient-pin-delete: linear-gradient(180deg, rgba(235, 0, 56, 0.15) 0%, #EB0038 100%);
$color-gradient-pin-face-id: linear-gradient(180deg, rgba(0, 161, 190, 0.15) 0%, #00A1BE 100%);
$color-gradient-warning: linear-gradient(102.68deg, #F08B2E 10.15%, #F4E130 93.1%);
$color-gradient-error: linear-gradient(114.44deg, #EB0038 0%, #FF8989 100%);
$color-gradient-orange: linear-gradient(102.68deg, #F35320 10.15%, #FFCB7C 91.78%);
$color-gradient-colorful: linear-gradient(102.68deg, #F35320 10.15%, #FAE73F 49.54%, #49FF7C 90.38%);