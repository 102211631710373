@import '~@ui/styles/tools';
@import '~@ui/styles/variables';

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1250px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}


.filter-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    border-radius: 76px;
    gap: 10px;

    min-width: unset;

    & > span { 
        margin-left: 0 !important;
    }
}

.button-container {
    margin: 0;
    padding: 0;
    border: none;

    &:not(:last-child) {
        margin-right: 0;
    }


}

@include media-mobile {
    .button-container {
        width: 100%;
    }
}